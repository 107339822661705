<template>
  <v-toolbar
    v-if="toShowNavItems"
    color="white"
    dense
    height="65px"
    style="box-shadow: 0px 1px 4px #aaaaaa"
  >
    <v-toolbar-title class>
      <span
        @click="goHome"
        class="primary-clr pointer bold"
        style="padding-right: 2px"
      >
        <img
          src="../../assets/v_logo_new.png"
          style="margin-top: 8px"
          height="35"
          width="90"
        />
      </span>
      <v-select
        :items="workingCountries"
        v-model="selectedCountry"
        item-value="value"
        class="country-menu-header"
        multiple
        style
        box
        item-text="value"
        @change="setCountry()"
        label="Working Country"
      >
      </v-select>
      <span v-if="toShowNavItems" class="salutation muted pl-5"
        >{{ adminName || "Admin" }}
        {{ adminRole ? " | " + adminRole + "" : "" }}</span
      >
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items v-if="toShowNavItems" class="hidden-sm-and-down">
      <v-btn
        v-permissions="'jobs/true'"
        flat
        to="/jobs"
        @click="selectActive(2)"
        :color="index === 2 ? 'orange darken-3' : 'grey darken-3'"
        ><v-icon>work_outline</v-icon> &nbsp;Jobs</v-btn
      >

      <v-btn
        v-permissions="'procurements/true'"
        flat
        to="/procurements"
        @click="selectActive(13)"
        :color="index === 13 ? 'orange darken-3' : 'grey darken-3'"
        ><v-icon>add_task</v-icon> &nbsp;Procurements</v-btn
      >
      <v-btn
        v-permissions="'sales/true'"
        flat
        to="/sales"
        @click="selectActive(12)"
        :color="index === 12 ? 'orange darken-3' : 'grey darken-3'"
        ><v-icon>schedule</v-icon> &nbsp;Sales</v-btn
      >
      <v-btn
        v-permissions="'tender/true'"
        flat
        to="/tender/Bid"
        @click="selectActive(25)"
        :color="index === 25 ? 'orange darken-3' : 'grey darken-3'"
        ><v-icon>playlist_add_check</v-icon> &nbsp;Tenders</v-btn
      >
      <v-btn
        v-permissions="'request-callback/true'"
        flat
        to="/request-callback"
        @click="selectActive(6)"
        :color="index === 6 ? 'orange darken-3' : 'grey darken-3'"
        ><v-icon>restore</v-icon> &nbsp;Request Callbacks</v-btn
      >
      <v-btn
        v-permissions="'mapping-management/true'"
        flat
        target="_blank"
        @click="checkPermissionMapping()"
        >Mapping</v-btn
      >

      <!-- NEW DROPDOWN FOR PROFILE--------- author : Suraj -->
      <template v-if="toShowNavItems">
        <div class="text-center v-btn v-btn--flat v-btn--router">
          <div v-if="unreadCount">
            <v-avatar
              v-if="
                unreadCount != 0 ||
                (jobCommentsUEA > 0 && selectedCountry.indexOf('UAE') >= 0) ||
                (jobCommentsPAK > 0 && selectedCountry.indexOf('PAK') >= 0) ||
                (jobCommentsKSA > 0 && selectedCountry.indexOf('KSA') >= 0) ||
                (jobCommentsUAELA > 0 && selectedCountry.indexOf('UAE LA') >= 0)
              "
              style="
                position: absolute;
                top: 0 px !important;
                right: 0 px;
                top: 5px;
                right: 115px;
                z-index: 7;
              "
              color="orange"
              size="22"
            >
              <span v-if="unreadCount > 99" class="white--text text-h5"
                >99+
              </span>
              <span v-else-if="unreadCount > 0" class="white--text text-h5">{{
                unreadCount
              }}</span>
              <span v-else class="white--text text-h5"></span>
            </v-avatar>
          </div>
          <NotificationTray
            :selectedCountry="selectedCountry"
            :jobCommentsKSA="jobCommentsKSA"
            :jobCommentsPAK="jobCommentsPAK"
            :jobCommentsUEA="jobCommentsUEA"
            :jobCommentsUAELA="jobCommentsUAELA"
            @removeDot="notificationRead()"
            :count="parseInt(unreadCount)"
            @unread-count="getUnreadNotificationCount()"
          />

          <v-menu transition="slide-x-transition" offset-y class="profile-drop">
            <v-toolbar-side-icon slot="activator">
              <v-icon>supervisor_account</v-icon>
            </v-toolbar-side-icon>
            <v-list style="width: 150px">
              <v-list-tile
                v-permissions="'drivers/true'"
                to="/drivers"
                @click="selectActive(3)"
                :color="index === 3 ? 'orange darken-3' : 'grey darken-3'"
              >
                <v-list-tile-title>Drivers</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                v-permissions="'transporter/true'"
                to="/transporter"
                @click="selectActive(16)"
                :color="index === 16 ? 'orange darken-3' : 'grey darken-3'"
              >
                <v-list-tile-title>Transporters</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                v-permissions="'customers/true'"
                to="/customers"
                @click="selectActive(7)"
                :color="index === 7 ? 'orange darken-3' : 'grey darken-3'"
              >
                <v-list-tile-title>Customers</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                flat
                to="/analytics"
                @click="
                  selectActive(30);
                  mixpanel();
                "
                :color="index === 30 ? 'orange darken-3' : 'grey darken-3'"
              >
                <v-list-tile-title>Analytics</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                to="/user-management"
                v-permissions="'user-management/true'"
                @click="selectActive(8)"
                :color="index === 8 ? 'orange darken-3' : 'grey darken-3'"
              >
                <v-list-tile-title>Users</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                to="/role-management"
                v-permissions="'role-management/true'"
                @click="selectActive(8)"
                :color="index === 8 ? 'orange darken-3' : 'grey darken-3'"
              >
                <v-list-tile-title>Roles</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                v-permissions="'shipment-documents/true'"
                to="/shipment-documents"
                style="margin-bottom: 10px"
                @click="selectActive(50)"
                :color="index === 50 ? 'orange darken-3' : 'grey darken-3'"
              >
                <v-list-tile-title
                  style="
                    white-space: inherit;
                    overflow: inherit;
                    height: initial;
                    line-height: 18px;
                  "
                  >Shipment Docs</v-list-tile-title
                >
              </v-list-tile>
            </v-list>
          </v-menu>
          <v-avatar
            v-if="newJob || newInquiry"
            style="
              position: absolute;
              top: 0 px !important;
              right: 0 px;
              top: 7px;
              right: 20px;
              z-index: 7;
            "
            color="orange"
            size="18"
          >
            <v-icon size="5" dark>dot</v-icon>
          </v-avatar>
          <v-menu transition="slide-x-transition" offset-y class="profile-drop">
            <v-toolbar-side-icon slot="activator">
              <v-icon>settings </v-icon>
            </v-toolbar-side-icon>
            <v-list>
              <v-list-tile
                v-permissions="'milestone/true'"
                to="/milestone"
                @click="selectActive(10)"
                :color="index === 10 ? 'orange darken-3' : 'grey darken-3'"
              >
                <v-list-tile-title>Milestones</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                to="/additionalCharge"
                @click="selectActive(60)"
                v-permissions="'additional-charge-name/true'"
                :color="index === 60 ? 'orange darken-3' : 'grey darken-3'"
              >
                <v-list-tile-title>Additional Charges</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                to="/dashboard"
                @click="selectActive(15)"
                :color="index === 15 ? 'orange darken-3' : 'grey darken-3'"
              >
                <v-list-tile-title>Booking Stats</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                v-permissions="'approvals/true'"
                to="/approvals"
                @click="
                  selectActive(35);
                  refresh();
                "
                :color="index === 35 ? 'orange darken-3' : 'grey darken-3'"
              >
                <v-list-tile-title>
                  Approvals
                  <v-avatar
                    color="orange"
                    v-if="newJob || newInquiry"
                    size="18"
                  >
                    <v-icon size="15" dark>notifications</v-icon>
                  </v-avatar>
                </v-list-tile-title>
              </v-list-tile>

              <v-list-tile
                to="/accounts"
                v-permissions="'accounts/true'"
                @click="selectActive(17)"
                :color="index === 17 ? 'orange darken-3' : 'grey darken-3'"
              >
                <v-list-tile-title>Accounts</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                v-permissions="'gps-devices/true'"
                to="/gps-devices"
                @click="selectActive(17)"
                :color="index === 17 ? 'orange darken-3' : 'grey darken-3'"
              >
                <v-list-tile-title>GPS Devices</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                @click="open"
                :color="index === 5 ? 'orange darken-3' : 'grey darken-3'"
              >
                <v-list-tile-title>Logout</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-toolbar-items>

    <!-- FOR RESPONSIVE  -->
    <template v-if="toShowNavItems" class="hidden-md-and-up">
      <div class="text-center v-btn v-btn--flat v-btn--router hidden-md-and-up">
        <!-- -SETTINGS- -->
        <v-menu offset-y class="profile-drop hidden-md-and-up">
          <v-toolbar-side-icon slot="activator"></v-toolbar-side-icon>
          <v-list>
            <v-list-tile
              v-permissions="'jobs/true'"
              to="/jobs"
              @click="selectActive(2)"
              :color="index === 2 ? 'orange darken-3' : 'grey darken-3'"
            >
              <v-list-tile-title>Jobs</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              v-permissions="'milestone/true'"
              to="/milestone"
              @click="selectActive(10)"
              :color="index === 10 ? 'orange darken-3' : 'grey darken-3'"
            >
              <v-list-tile-title>Milestone</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              to="/user-management"
              v-permissions="'user-management/true'"
              @click="selectActive(8)"
              :color="index === 8 ? 'orange darken-3' : 'grey darken-3'"
            >
              <v-list-tile-title>Users</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              to="/role-management"
              v-permissions="'role-management/true'"
              @click="selectActive(8)"
              :color="index === 8 ? 'orange darken-3' : 'grey darken-3'"
            >
              <v-list-tile-title>Roles</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              to="/vendor"
              @click="selectActive(26)"
              :color="index === 26 ? 'orange darken-3' : 'grey darken-3'"
            >
              <v-list-tile-title>Vendors</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              v-permissions="'redash'"
              flat
              target="_blank"
              v-bind:href="'https://app.redash.io/redash.trukkin/'"
              @click="selectActive(30)"
              :color="index === 30 ? 'orange darken-3' : 'grey darken-3'"
            >
              <v-list-tile-title>Re-dash</v-list-tile-title>
            </v-list-tile>

            <v-list-tile
              @click="open"
              :color="index === 5 ? 'orange darken-3' : 'grey darken-3'"
            >
              <v-list-tile-title>Logout</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
      </div>
    </template>
    <v-snackbar
      :timeout="6000"
      top
      color="red darken-2"
      v-model="error"
      class="white--text"
      v-if="error"
      >{{ error }}</v-snackbar
    >

    <v-dialog
      v-model="dialog"
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-show="!!message" class="pa-4">{{ message }}</v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click.native="agree">Yes</v-btn>
          <v-btn color="grey" text @click.native="cancel">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-toolbar>
</template>

<script>
import { StorageKeys } from "../../constants/constants";
import { EventBus } from "../../event-bus.js";
import { notificationList } from "@/constants/api-urls.js";
import removeLocalstorageMixin from "@/mixins/removeLocalstorageMixin";
import checkPermissionsMixin from "@/mixins/checkPermissionsMixin";
import NotificationTray from "./NotificationTray.vue";
import Swal from "sweetalert2";
import moment from "moment";
import { mixins } from "vue-chartjs";
export default {
  mixins: [checkPermissionsMixin, removeLocalstorageMixin],
  created() {
    this.unreadCount = localStorage.getItem("unreadCount");

    delete this.axios.defaults.headers.common["token"];
    let token = localStorage.getItem(StorageKeys.SessionKey);
    let config = {
      headers: {
        authorization: `${token}`,
      },
    };
    this.u = localStorage.getItem("user") ? localStorage.getItem("user") : "";
    this.t = config.headers.authorization ? config.headers.authorization : "";

    EventBus.$on("updateCountryGlobally", (obj) => {
      this.selectedCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.value === obj.country) {
          this.selectedCountry.push(key.value);
          key.isSelected = true;
        } else {
          key.isSelected = false;
        }
      });
      localStorage.setItem(
        "workingCountries",
        JSON.stringify(this.workingCountries)
      );

      this.$router.push(obj.redirect);
    });
    this.$eventBus.$on("unread-notify-count", (val) => {
      this.getUnreadNotificationCount();
    });

    this.$eventBus.$on("show-nav-items", (val) => {
      this.toShowNavItems = val;
      this.adminName = localStorage.getItem("user");
      this.adminRole = localStorage.getItem("role");
    });

    EventBus.$on("setCountries", (val) => {
      this.workingCountries = val;
      this.selectedCountry = [];
      this.selectedCountry = [val[0].value];
      this.notificationListing = [];
      this.jobComments = 0;
    });
    EventBus.$on("setCountriesfromUrl", (val) => {
      this.notificationListing = [];
      this.jobComments = 0;
      this.selectedCountry = [];
      this.selectedCountry = val;
    });
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      this.selectedCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          this.selectedCountry.push(key.value);
        }
      });
    }

    this.$eventBus.$on("hide-navbar", () => {
      this.show = false;
    });

    EventBus.$on("logged-in-success", (data) => {
      this.startSocket();
      //console.log("logged in successful------------------------------------");
      this.adminName = data.name;
      this.adminRole = data.roleType;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `${token}`,
        },
      };
      this.u = localStorage.getItem("user");
      this.t = config.headers.authorization;
      this.getUnreadNotificationCount();
    });
    EventBus.$on("in-waybill", () => {
      this.toShowNavItems = false;
    });
    this.startSocket();
    //
  },
  beforeDestroy() {
    this.$eventBus.$off("countryChanged");
  },
  watch: {
    selectedCountry(val) {},
    online() {
      if (!navigator.onLine) {
        Swal.fire({
          title: "Oops!",
          text: "Your internet connection seems to be offline.Please try again.",
          type: "error",
          allowOutsideClick: false,
          confirmButtonColor: "orange",
          confirmButtonText: "Okay",
        }).then((result) => {
          if (result.value) {
          }
        });
      }
    },
  },
  components: {
    EventBus,
    NotificationTray,
  },
  data() {
    return {
      opeartionName: "",
      onLine: navigator.onLine ? true : false,
      error: "",
      operationName1: "",
      unreadCount: 0,
      newJob: 0,
      newInquiry: 0,
      jobComments: 0,
      jobCommentsUEA: 0,
      jobCommentsUAELA: 0,
      jobCommentsPAK: 0,
      jobCommentsKSA: 0,
      selectedCountry: [],
      workingCountries: [],
      workingCountry: [],
      notificationListing: [],
      t: null,
      u: null,
      items: [
        { name: "Logout", link: "" },
        { name: "Finance" },
        { name: "Users" },
      ],
      index: Number(localStorage.getItem("activeMenu")) || 2,
      toShowNavItems: true,
      adminName: localStorage.getItem("user"),
      adminRole: localStorage.getItem("role"),
      show: true,
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {
        color: "#ffc04c",
        width: 290,
        zIndex: 200,
      },
    };
  },
  methods: {
    mixpanel() {
      this.track("Analytics", {
        $email: localStorage.getItem("email"),
        $name: localStorage.getItem("user"),
        WorkingCountry: localStorage.getItem("workingCountry"),
      });
    },
    socketEvent(permission, eventEmitTo, data) {
      let operationName = permission;
      let userPermissions = JSON.parse(
        localStorage.getItem("permissions")
      ).permissions;
      userPermissions.filter((x) => {
        if (x.operationName == operationName) {
          this.$eventBus.$emit(eventEmitTo, data);

          if (
            permission != "view-sales-invoice" ||
            permission != "view-purchase-invoice" ||
            permission != "generate-downaload-so-si-for-accounts" ||
            permission != "generate-downaload-po-pi-for-accounts"
          ) {
            if (data.workingCountry.indexOf("UAE") >= 0) {
              this.jobCommentsUEA = this.jobCommentsUEA + 1;
              this.getUnreadNotificationCount();
              this.$eventBus.$emit("notify");
            }
            if (data.workingCountry.indexOf("PAK") >= 0) {
              this.jobCommentsPAK = this.jobCommentsPAK + 1;
              this.getUnreadNotificationCount();
              this.$eventBus.$emit("notify");
            }
            if (data.workingCountry.indexOf("KSA") >= 0) {
              this.jobCommentsKSA = this.jobCommentsKSA + 1;
              this.getUnreadNotificationCount();
              this.$eventBus.$emit("notify");
            }
            if (data.workingCountry.indexOf("UAE LA") >= 0) {
              this.jobCommentsUAELA = this.jobCommentsUAELA + 1;
              this.getUnreadNotificationCount();
              this.$eventBus.$emit("notify");
            }
          }
        }
      });
    },

    socketEventRoleBased(permission, eventEmitTo, data) {
      let roles = JSON.parse(localStorage.getItem("permissions")).roles;
      roles = roles.map((x) => x.role.roleName.toLowerCase());
      if (roles.includes(permission)) {
        eventEmitTo ? this.$eventBus.$emit(eventEmitTo, data) : "";
        if (data.workingCountry.indexOf("UAE") >= 0) {
          this.jobCommentsUEA = this.jobCommentsUEA + 1;
          this.getUnreadNotificationCount();
          this.$eventBus.$emit("notify");
        }
        if (data.workingCountry.indexOf("PAK") >= 0) {
          this.jobCommentsPAK = this.jobCommentsPAK + 1;
          this.getUnreadNotificationCount();
          this.$eventBus.$emit("notify");
        }
        if (data.workingCountry.indexOf("KSA") >= 0) {
          this.jobCommentsKSA = this.jobCommentsKSA + 1;
          this.getUnreadNotificationCount();
          this.$eventBus.$emit("notify");
        }
        if (data.workingCountry.indexOf("UAE LA") >= 0) {
          this.jobCommentsUAELA = this.jobCommentsUAELA + 1;
          this.getUnreadNotificationCount();
          this.$eventBus.$emit("notify");
        }
      }
    },

    // socketFullData(permission, eventEmitTo, data) {
    //   let roles = JSON.parse(localStorage.getItem("permissions")).roles;
    //   roles = roles.map((x) => x.role.roleName.toLowerCase());
    //   if (roles.includes(permission)) {
    //     eventEmitTo ? this.$eventBus.$emit(eventEmitTo, data) : "";
    //     if (data.workingCountry.indexOf("UAE") >= 0) {
    //       this.jobCommentsUEA = this.jobCommentsUEA + 1;
    //       this.getUnreadNotificationCount();
    //       this.$eventBus.$emit("notify");
    //     }
    //     if (data.workingCountry.indexOf("PAK") >= 0) {
    //       this.jobCommentsPAK = this.jobCommentsPAK + 1;
    //       this.getUnreadNotificationCount();
    //       this.$eventBus.$emit("notify");
    //     }
    //     if (data.workingCountry.indexOf("KSA") >= 0) {
    //       this.jobCommentsKSA = this.jobCommentsKSA + 1;
    //       this.getUnreadNotificationCount();
    //       this.$eventBus.$emit("notify");
    //     }
    //   }
    // },

    checkPermissionMapping() {
      const { serverUrl, rbacUrl, apiUrl } = this.constants;
      this.$http
        .post(`${rbacUrl}/checkUserPermission`, {
          userId: localStorage.getItem("userId"),
        })
        .then((res) => {
          if (!res.data.data) {
            this.loading = false;
          }
          let old = res.data.data.roles;
          old = old.map((x) => x.role.roleName.toLowerCase());
          let roles = JSON.parse(localStorage.getItem("permissions")).roles;
          roles = roles.map((x) => x.role.roleName.toLowerCase());
          old.sort();
          roles.sort();
          if (old.toString() != roles.toString()) {
            this.showScreen = false;
            localStorage.setItem("permissions", JSON.stringify(res.data.data));
            Swal.fire({
              title: "Oops!",
              text: "Your permissions have changed .",
              type: "error",
              allowOutsideClick: false,
              allowEscapeKey: false,
              confirmButtonColor: "orange",
              confirmButtonText: "Okay",
            }).then((result) => {
              localStorage.setItem("first-load", true);
              this.$router.replace("/");
              location.reload();
            });
          }
          if (old.toString() == roles.toString()) {
            localStorage.setItem("permissions", JSON.stringify(res.data.data));
            window.open(
              this.constants.mappingUrl +
                "/#/redirecting-user?key=" +
                this.t +
                "&username=" +
                this.u +
                "&country=" +
                JSON.stringify(this.selectedCountry)
            );
          }
        });
    },
    notificationRead() {
      this.jobComments = 0;
      if (this.selectedCountry.indexOf("UAE") >= 0) {
        this.jobCommentsUEA = 0;
      }
      if (this.selectedCountry.indexOf("PAK") >= 0) {
        this.jobCommentsPAK = 0;
      }
      if (this.selectedCountry.indexOf("KSA") >= 0) {
        this.jobCommentsKSA = 0;
      }
      if (this.selectedCountry.indexOf("UAE LA") >= 0) {
        this.jobCommentsUAELA = 0;
      }
    },
    refresh() {
      this.newJob = 0;
      this.newInquiry = 0;
    },
    startSocket: function () {
      console.log("------socket----------------------------");
      var socket = io(this.constants.apiUrl, {
        query: "adminToken=" + localStorage.getItem(StorageKeys.SessionKey),
        forceNew: true,
      });

      socket.on("connect", () => {});
      socket.on("1", (data) => {
        cosnole.log("advanceRequestttttttt");
        this.socketEventRoleBased("accounts", "finance", data);
        if (this.selectedCountry.includes(data.workingCountry)) {
          new Audio(
            "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
          ).play();
        }
      });
      socket.on("salesInvoice", (data) => {
        console.log("salesInvoice", data);
        console.log("-------------------salesInvoice------------");
        if (this.$route.name == "Accounts" || this.$route.name == "Advance") {
          this.socketEvent(
            "generate-downaload-so-si-for-accounts",
            "accounts-sales-invoice",
            data
          );
        } else {
          this.socketEvent(
            "view-sales-invoice",
            "job-details-sales-invoice",
            data
          );
        }
      });
      socket.on("salesOrder", (data) => {
        console.log("salesOrder", data);
        console.log("-------------------salesOrder------------");
        if (this.$route.name == "Accounts" || this.$route.name == "Advance") {
          this.socketEvent(
            "generate-downaload-so-si-for-accounts",
            "accounts-sales-order",
            data
          );
        } else {
          this.socketEvent(
            "view-sales-invoice",
            "job-details-sales-order",
            data
          );
        }
      });
      socket.on("purchaseOrder", (data) => {
        console.log("-------------------purchaseOrder------------", data);
        if (this.$route.name == "Accounts" || this.$route.name == "Advance") {
          this.socketEvent(
            "generate-downaload-po-pi-for-accounts",
            "accounts-purchase-order",
            data
          );
        } else {
          this.socketEvent(
            "view-purchase-invoice",
            "job-details-purchase-order",
            data
          );
        }
      });
      socket.on("vasPak", (data) => {
        this.socketEvent("view-purchase-invoice", "VASPAK", data);
      });
      socket.on("changeMilestone", (data) => {
        console.log(data);
        this.socketEvent("change-milestone", "refresh-milstoneChange", data);
      });

      socket.on("togglePurchaseInvoiceSwitch", (data) => {
        this.socketEvent(
          "view-purchase-invoice",
          "togglePurchaseInvoiceSwitch",
          data
        );
      });
      socket.on("purchaseInvoice", (data) => {
        console.log("-------------------purchaseInvoice------------", data);
        if (this.$route.name == "Accounts" || this.$route.name == "Advance") {
          this.socketEvent(
            "generate-downaload-po-pi-for-accounts",
            "accounts-purchase-invoice",
            data
          );
        } else {
          this.socketEvent(
            "view-purchase-invoice",
            "job-details-purchase-invoice",
            data
          );
        }
      });
      socket.on("assignment-salesInvoice", (data) => {
        console.log("----------sales--assignment_Invoice", data);
        if (this.$route.name == "Accounts" || this.$route.name == "Advance") {
          this.socketEvent(
            "generate-downaload-po-pi-for-accounts",
            "accounts-purchase-invoice2",
            data
          );
        } else {
          this.socketEvent(
            "generate-downaload-po-pi-for-accounts",
            "sales-invoice-assignment",
            data
          );
        }
      });
      // socket.on("customerRecipt", (data) => {
      //   console.log("customerreciptedit", data);
      //   this.socketEvent("collect-gps", "customerRecipt", data);
      // });
      socket.on("IBUSERUPDATE", (data) => {
        console.log(data);
        this.socketEvent("collect-gps", "Ibupdate", data);
      });

      socket.on("jobApprovalPending", (data) => {
        this.socketEvent("job-approval", "job-approval-notification", data);
        new Audio(
          "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
        ).play();
      });
      socket.on("customerRecipt", (data) => {
        console.log("customerreciptedit", data);
        this.socketEvent("collect-gps", "customerRecipt", data);
      });
      socket.on("inquryApprovalPending", (data) => {
        this.socketEvent("sales-approval", "sales-approval-notification", data);
        new Audio(
          "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
        ).play();
      });
      socket.on("collectGpsDevice", (data) => {
        this.socketEvent("collect-gps", "imei-section-collect-gps", data);
      });
      socket.on("Additionalcharge", (data) => {
        console.log("additionalCharge", data);
        // this.socketEventRoleBased("accounts", "additional-charge-update", data);
        this.socketEvent(
          "view-additional-charge-name",
          "additionalchargeUpdate",
          data
        );
      });
      socket.on("comments", (data) => {
        // let operationName = "job-comments";
        this.socketEvent("job-comments", "new-sales-comment", data);

        if (this.selectedCountry.includes(data.workingCountry)) {
          new Audio(
            "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
          ).play();
        }
      });

      socket.on("bookingFromCustomer", (data) => {
        this.socketEventRoleBased("procurements", "procurement", data);
        if (this.selectedCountry.includes(data.workingCountry)) {
          new Audio(
            "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
          ).play();
        }
      });
      socket.on("newCustomer", (data) => {
        console.log("nwCustomer", data);
        this.socketEventRoleBased("customers", "customers", data);
        if (this.selectedCountry.includes(data.workingCountry)) {
          new Audio(
            "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
          ).play();
        }
      });
      // add new driver socket
      socket.on("addDriver", (data) => {
        this.socketEventRoleBased("drivers", "drivers", data);
        if (this.selectedCountry.includes(data.workingCountry)) {
          new Audio(
            "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
          ).play();
        }
      });
      socket.on("callbackFromCustomer", (data) => {
        this.socketEventRoleBased("request-callback", "requestCallback", data);
        if (this.selectedCountry.includes(data.workingCountry)) {
          new Audio(
            "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
          ).play();
        }
      });
      socket.on("customerCreated", (data) => {
        console.log("----------------------------------------------------");
        this.socketEventRoleBased("customers", "customers", data);

        if (this.selectedCountry.includes(data.workingCountry)) {
          new Audio(
            "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
          ).play();
        }
      });
      socket.on("inquiryCreated", (data) => {
        console.log("----------------inquirycreated----------------");
        this.socketEventRoleBased("sales", "Sales", data);

        if (this.selectedCountry.includes(data.workingCountry)) {
          new Audio(
            "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
          ).play();
        }
      });
      socket.on("gpsCollectedSocket", (data) => {
        this.socketEventRoleBased("accounts", null, data);
        if (this.selectedCountry.includes(data.workingCountry)) {
          new Audio(
            "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
          ).play();
        }
      });
      socket.on("notificationDriverPayment", (data) => {
        this.socketEventRoleBased("accounts", null, data);
        if (this.selectedCountry.includes(data.workingCountry)) {
          new Audio(
            "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
          ).play();
        }
      });
      socket.on("rejectAdvance", (data) => {
        this.socketEventRoleBased("accounts", "finance", data);
        if (this.selectedCountry.includes(data.workingCountry)) {
          new Audio(
            "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
          ).play();
        }
      });
      socket.on("issueAdvance", (data) => {
        console.log("issueAdvance");
        this.socketEventRoleBased("accounts", "finance", data);
        if (this.selectedCountry.includes(data.workingCountry)) {
          new Audio(
            "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
          ).play();
        }
      });

      socket.on("advancePaidPush", (data) => {
        console.log("-------------advancePAid");
        this.socketEventRoleBased("accounts", "assignments", data);
        this.socketEventRoleBased("accounts", "subpartiallist", data);

        if (this.selectedCountry.includes(data.workingCountry)) {
          new Audio(
            "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
          ).play();
        }
      });
      socket.on("DocReceivedByAccounts", (data) => {
        console.log("-------------advancePAid");
        this.socketEventRoleBased("accounts", "assignments", data);
        if (this.selectedCountry.includes(data.workingCountry)) {
          new Audio(
            "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
          ).play();
        }
      });
      socket.on("paymentDone", (data) => {
        console.log("paymentDone");
        console.log(data);
        this.socketEventRoleBased("accounts", "assignments", data);

        if (this.selectedCountry.includes(data.workingCountry)) {
          new Audio(
            "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
          ).play();
        }
      });
      socket.on("readyToPayFinalPayment", (data) => {
        console.log("-----readyToPayFinalPayment");
        this.socketEventRoleBased("accounts", "finance", data);
        if (this.selectedCountry.includes(data.workingCountry)) {
          new Audio(
            "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
          ).play();
        }
      });

      socket.on("additionalCharges", (data) => {
        console.log("---additionalCharges-----------", data);
        setTimeout(() => {
          this.socketEventRoleBased("accounts", "additionalchargeAdd", data);
        }, 3000);

        if (this.selectedCountry.includes(data.workingCountry)) {
          new Audio(
            "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
          ).play();
        }
      });
      socket.on("readyToPay", (data) => {
        console.log("-----readyToPay-----");
        this.socketEventRoleBased("accounts", "finance", data);
        if (this.selectedCountry.includes(data.workingCountry)) {
          new Audio(
            "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
          ).play();
        }
      });
      socket.on("cancelAdvance", (data) => {
        console.log("----canceladvance");
        this.socketEventRoleBased("accounts", "finance", data);
        if (this.selectedCountry.includes(data.workingCountry)) {
          new Audio(
            "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
          ).play();
        }
      });
      socket.on("RejectedAdvance", (data) => {
        console.log("----RejectedAdvance");
        this.socketEventRoleBased("jobs", "assignments", data);
        if (this.selectedCountry.includes(data.workingCountry)) {
          new Audio(
            "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
          ).play();
        }
      });

      socket.on("driverUpdated", (data) => {
        console.log("----driverUpdated");

        this.socketEventRoleBased("transporter", "transporterDriver", data);
        if (this.selectedCountry.includes(data.workingCountry)) {
          new Audio(
            "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
          ).play();
        }
      });
      socket.on("newTransporter", (data) => {
        this.socketEventRoleBased("transporter", "newtransporters", data);
        if (this.selectedCountry.includes(data.workingCountry)) {
          new Audio(
            "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
          ).play();
        }
      });

      socket.on("driverNotAssignedYet", (data) => {
        this.socketEventRoleBased("jobs", null, data);
        if (this.selectedCountry.includes(data.workingCountry)) {
          new Audio(
            "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
          ).play();
        }
      });

      socket.on("bidPush", (data) => {
        if (data.workingCountry.indexOf("UAE") >= 0) {
          this.jobCommentsUEA = this.jobCommentsUEA + 1;
        }
        if (data.workingCountry.indexOf("PAK") >= 0) {
          this.jobCommentsPAK = this.jobCommentsPAK + 1;
        }
        if (data.workingCountry.indexOf("KSA") >= 0) {
          this.jobCommentsKSA = this.jobCommentsKSA + 1;
        }
        if (data.workingCountry.indexOf("UAE LA") >= 0) {
          this.jobCommentsUAELA = this.jobCommentsUAELA + 1;
        }
        new Audio(
          "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
        ).play();
        return;
      });

      socket.on("unassignedJob", (data) => {
        EventBus.$emit("unassignedJob-notification", data);
      });
      socket.on("assignedDriver", (data) => {
        EventBus.$emit("assignedDriver-notification", data);
      });
      socket.on("IBUSERUPDATE", (data) => {
        console.log(data);
        // EventBus.$emit("assignedDriver-notification", data);
      });
      socket.on("completeAssignment", (data) => {
        EventBus.$emit("completeAssignment-notification", data);
      });
    },
    setCountry() {
      this.error = "";
      if (!this.selectedCountry.length) {
        this.error = "Please select at least one working country";
        return;
      }
      this.workingCountries.forEach((key, index) => {
        if (this.selectedCountry.indexOf(key.value) >= 0) {
          key.isSelected = true;
        } else key.isSelected = false;
      });
      if (this.selectedCountry.length) {
        EventBus.$emit("countryChanged", this.selectedCountry);
        localStorage.setItem(
          "workingCountries",
          JSON.stringify(this.workingCountries)
        );
        this.checkPermission();
        //  this.$emit("notification-List")
        this.getUnreadNotificationCount();
      }
    },
    internet() {
      if (!navigator.onLine) {
        this.loading = false;
        this.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }
    },
    open(title, message, options) {
      this.dialog = true;
      this.title = "Do You Want to Logout?";
      this.message = "Click Yes to Logout";
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.logout();
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
    selectActive(index) {
      localStorage.setItem("activeMenu", index);
      this.index = Number(localStorage.getItem("activeMenu"));
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        this.selectedCountry = [];
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            this.selectedCountry.push(key.value);
          }
        });
      }
    },
    async getUnreadNotificationCount() {
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        let workingCountry = [];
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            workingCountry.push(key.value);
          }
        });
        this.workingCountry = JSON.stringify(workingCountry);
      }
      let url = `/commentRoute/v1/getNotificationCount?workingCountry=${this.workingCountry}`;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const response = await this.axios.get(
        this.constants.apiUrl + url,
        config
      );
      if (response.status === 200) {
        this.unreadCount = response.data.data.totalCount;
        localStorage.setItem("unreadCount", this.unreadCount);
      } else {
        this.loading = false;
      }
    },
    goHome() {
      this.index = 2;
      localStorage.setItem("activeMenu", this.index);
      this.$router.push("/jobs");
    },
    logout() {
      const _parentScope = this;
      let url = "/dmsAdmin/logout";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {};

      this.axios.post(this.constants.apiUrl + url, {}, config).then(
        (response) => {
          this.index = 2;
          this.resetId();
          import("../../constants/constants.js").then(({ StorageKeys }) => {
            this.removeStorage("clearAll");
            localStorage.removeItem(StorageKeys.SessionKey);
            _parentScope.$router.replace("/auth");
          });
        },
        (error) => {
          this.error = "Failed to Logout";
        }
      );
    },
  },
};
</script>

<style>
#nav.total.avatar .count {
  background-color: darkorange;
  color: white !important;
  padding: 0 5px;
  font-size: 14x !important;
  border-radius: 5px;
  margin-left: 10px;
}
#nav.total.avatar {
  position: absolute;
  top: 0 px !important;
  right: 0 px;
  top: -74px;
  right: -15px;
  z-index: 7;

  min-width: 280px !important;
  height: 50px !important;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border-radius: 0;
}
.tab {
  margin-bottom: 20px;
}
.total.avatar span {
  color: darkorange !important;
}
#navspan.status-count {
  font-size: 12px;

  font-weight: 900 !important;
}
.profile-drop .v-btn {
  background-color: white !important;
  color: #424242 !important;
  box-shadow: none !important;
}
.v-toolbar__side-icon {
  border: 2px solid darkorange;
  color: darkorange !important;
}

.count {
  background-color: darkorange;
  color: white !important;
  font-size: 14px !important;
  padding: 0 5px;
  border-radius: 5px;
  margin-left: 10px;
}
.tt {
  position: absolute;
  left: 129px;
  bottom: 18px;
}
.scroll {
  height: 350px;
  overflow: scroll;
}
.list-noti {
  border: 1.5px solid orange;
  border-radius: 6px;
  margin: 20px;
  padding: 10px 0px;
}
.list-noti div {
  line-height: inherit;
  line-height: 16px;
  font-size: 14px;
  height: auto;
}
.list-noti p {
  margin-bottom: 0;
}
.tag-orange {
  color: #ff8c00;
  font-size: 13px;
}
.tag-gray {
  color: #b1abab;
  font-size: 12px;
}
.gray-dark {
  color: #4d4d4d;
}
.line-height-set {
  white-space: inherit !important;
}
.v-list__tile__title .line-height-set {
  white-space: inherit !important;
}
</style>
